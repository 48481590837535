import { createContext, useContext, useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

const AppContext = createContext();


function getUser(){
    let user = null;

    if (typeof window !== 'undefined') {
        user = window.localStorage.getItem("user")
        if (user) {
            user = JSON.parse(user)
        }
    }

    return user
}



export function AppWrapper({ children }) {
    
    const [user, setUser] = useState(getUser())
    const navigate = useNavigate();

    useEffect(() => {
        console.log('user', user);
        if (user) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${user.jwt}`);

            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`https://api.ginoboxingstudio.com/api/users/me`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log('result', result);
                    if (result.error) {
                        console.log('tologin');
                        navigate('/login', { replace: true });
                        setUser(null)
                    } else {
                        setUser({...user, user: result})
                    }
                }
            ).catch(error => {
                console.log('error', error)
                navigate('/login', { replace: true });
            })
        }
    }, [])

    useEffect(() => {
        window.localStorage.setItem("user", JSON.stringify(user))
    }, [user])

    const refreshUser = () => {
        if (user) {
            const myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${user.jwt}`);

            const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`https://api.ginoboxingstudio.com/api/users/me`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    console.log('result', result);
                    if (result.error) {
                        navigate('/login', { replace: true });
                        setUser(null)
                    } else {
                        setUser({...user, user: result})
                    }
                }
                
            ).catch(error => {
                console.log('error', error)
                navigate('/login', { replace: true });
            })
        }
    }

    return (
        <AppContext.Provider value={{user, setUser, refreshUser}}>
            {children}
        </AppContext.Provider>
    )
}

export function useAppContext(){
    return useContext(AppContext);
}