import PropTypes from 'prop-types';
// @mui
import { Box, Card, Link, Typography, Stack, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';

// utils
import { fCurrency } from '../../../utils/formatNumber';
// components
import Label from '../../../components/label';
import { ColorPreview } from '../../../components/color-utils';
import { useAppContext } from '../../../context'

// ----------------------------------------------------------------------

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, price, nbSessions, url, eco, img, validity } = product;
  const {user, setUser} = useAppContext();
  const navigate = useNavigate();

  return (
    <Card>
      <Box sx={{ pt: '100%', position: 'relative' }}>
        <StyledProductImg alt={name} src={img} />
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack direction="column" alignItems="flex-start" justifyContent="space-between" >
          <Typography variant="subtitle1">
            {price}	€
          </Typography>
          <Typography variant='caption' minHeight={18}>
            {eco}
          </Typography>
          <Typography variant='caption' minHeight={18}>
            {validity}
          </Typography>
        </Stack>
        <Button variant="contained" onClick={() => {
          if (user.user.id) {
            window.open(`${url}?client_reference_id=${user.user.id}`,'_blank')
          } else {
            navigate('/login', { replace: true });
          }
        }}>Acheter</Button>
      </Stack>
    </Card>
  );
}
