// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/scroll-to-top';
import { StyledChart } from './components/chart';
import { AppWrapper, useAppContext } from './context'

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeProvider>
      <AppWrapper>
        <ScrollToTop />
        <StyledChart />
        <Router />
      </AppWrapper>
    </ThemeProvider>
  );
}
