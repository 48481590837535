import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
// @mui
import { Container, Stack, Typography } from '@mui/material';
// components
import { useNavigate } from 'react-router-dom';
import { ProductSort, ProductList, ProductCartWidget, ProductFilterSidebar } from '../sections/@dashboard/products';
import { useAppContext } from '../context';

// mock
// import PRODUCTS from '../_mock/products';

// ----------------------------------------------------------------------

export default function ProductsPage() {
  const [openFilter, setOpenFilter] = useState(false);
  const { user, setUser } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login', { replace: true });
    }
  }, [user]);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <>
      <Helmet>
        <title> Sessions : Gino Boxing</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Packs
        </Typography>

        <ProductList
          user={user}
          products={[
            {
              id: 1,
              name: "Séance d'essai",
              price: 17,
              nbSessions: 1,
              url: 'https://buy.stripe.com/14kaHNgg3bEZgWA7sv',
              img: '/assets/images/products/Silver.png',
              validity: 'Valable 2 mois',
            },
            {
              id: 1,
              name: 'SILVER - 1 session',
              price: 27,
              nbSessions: 1,
              url: 'https://buy.stripe.com/00g5nt9RF24p8q4fYY',
              img: '/assets/images/products/Silver.png',
              validity: 'Valable 2 mois',
            },
            {
              id: 1,
              name: 'GOLD - 5 sessions',
              eco: '[Économie 15€]',
              price: 120,
              nbSessions: 5,
              url: 'https://buy.stripe.com/9AQ9DJd3ReRbgWA5kl',
              img: '/assets/images/products/Gold.png',
              validity: 'Valable 2 mois',
            },
            {
              id: 1,
              name: 'PLATINUM - 10 sessions',
              // eco: '[Économie 60€]',
              // price: 210,
              // nbSessions: 10,
              // url: "https://buy.stripe.com/8wMaHNfbZ9wReOs28a",
              eco: '[Économie 80€]',
              price: 190,
              nbSessions: 10,
              url: 'https://buy.stripe.com/4gw5nt1l9gZjgWA3ch',
              img: '/assets/images/products/Platinum.png',
              validity: 'Valable 4 mois',
            },
            {
              id: 1,
              name: 'DIAMOND - 20 sessions',
              // eco: '[Économie 160€]',
              // price: 380,
              // nbSessions: 20,
              // url: "https://buy.stripe.com/14kg272pd4cx21G7sw",
              eco: '[Économie 200€]',
              price: 340,
              nbSessions: 20,
              url: 'https://buy.stripe.com/aEU17dfbZgZjfSwcMS',
              img: '/assets/images/products/Platinum.png',
              validity: 'Valable 6 mois',
            },
          ]}
        />
      </Container>
    </>
  );
}
