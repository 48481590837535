import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography, Divider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useAppContext } from '../../../context'

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const {user, setUser} = useAppContext();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConf, setShowPasswordConf] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confPassword, setConfPassword] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');

  const handleClick = () => {

    if (password !== confPassword) {
      alert('Les mots de passe ne correspondent pas')
      return ;
    }
      
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify({
          email,
          username: email,
          password,
          firstname,
          lastname,
          credits: 0
        })
    };

    fetch(`https://api.ginoboxingstudio.com/api/auth/local/register`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.error) {
          alert('Une erreur est survenue')
        } else {
          setUser(result)
          navigate('/dashboard', { replace: true });
        }
console.log(result);
      })
      .catch(error => alert('Email ou mot de passe incorrect')
      );

  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
      <TextField value={lastname} onChange={(e) => setLastname(e.target.value)} name="lastname" label="Nom" />
        <TextField value={firstname} onChange={(e) => setFirstname(e.target.value)} name="firstname" label="Prénom" />
      </Stack>

      <Stack spacing={3}>
        <TextField value={email} onChange={(e) => setEmail(e.target.value)} name="email" label="Email" />

        <TextField
        value={password} onChange={(e) => setPassword(e.target.value)}
          name="password"
          label="Mot de passe"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
        value={confPassword} onChange={(e) => setConfPassword(e.target.value)}
          name="passwordConf"
          label="Confirmer le Mot de passe"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPasswordConf(!showPasswordConf)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover">
          Mot de passe oublié?
        </Link>
      </Stack> */}

      <Divider />

      <LoadingButton sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        S'inscrire
      </LoadingButton>
    </>
  );
}
