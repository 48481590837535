import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, TextField, Select, MenuItem, InputLabel, FormControl, Box, Button} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import 'moment/locale/fr'

// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import { useAppContext } from '../context';

const dayComp = {
  0: 'Dimanche',
  1: 'Lundi',
  2: 'Mardi',
  3: 'Mercredi',
  4: 'Jeudi',
  5: 'Vendredi',
  6: 'Samedi',
}

// ----------------------------------------------------------------------

function Item(props) {
  const { sx, ...other } = props;
  if (props.isNotAvailable === true) {
    return (
      <Box sx={{
        shapeOutside: 'circle()',
        clipPath: 'circle()',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
            backgroundColor: '#dfe3e855', borderRadius: 50, textAlign: 'center', lineHeight: '100px', color: 'black'
      }}  {...other} onClick={() => {}}>Indisponible</Box>
  
    )
  }
  return (
    <Box sx={{
      ':hover': {
        bgcolor: '#d8987c', // theme.palette.primary.main
        color: 'black',
      },
      cursor: 'pointer',
      shapeOutside: 'circle()',
    clipPath: 'circle()',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
       backgroundColor: props.isSelected ? '#d8987c': '#d8987caa', borderRadius: 50, textAlign: 'center', lineHeight: '100px', color: 'black'
    }}  {...other} />
  );
}

function ItemStepper(props) {
  const { sx, ...other } = props;
  if (props.isNotAvailable === true) {
    return (
      <Box sx={{
        ml: 'auto', mr: 'auto',
        width: props.width ? props.width : '100%', height: 30, backgroundColor: '#dfe3e855', borderRadius: 50, textAlign: 'center', lineHeight: '30px', color: 'black'
      }}  {...other} onClick={() => {}}>Indisponible</Box>
  
    )
  }
  return (
    <Box sx={{
      ml: 'auto', mr: 'auto',
      width: props.width ? props.width : '100%', height: 30, backgroundColor: props.isSelected ? '#d8987c': '#d8987caa', borderRadius: 50, textAlign: 'center', lineHeight: '30px', color: 'black'
    }}  {...other} />
  );
}

export default function DashboardAppPage() {
  const theme = useTheme();
  const {user, setUser, refreshUser} = useAppContext();
  const navigate = useNavigate();

  const [value, setValue] = useState(null);
  const [slot, setSlot] = useState(null);
  const [bags, setBags] = useState([]);
  const [selectedBag, setSelectedBag] = useState(0);
  const [timeSlots, setTimeSlots] = useState([]);
  const[reservations, setReservations] = useState([]);
  const [bagsTaken, setBagsTaken] = useState([]);

  
  useEffect(() => {
    if (!user) {
      navigate('/login', { replace: true });
    }
  }, [user])

  useEffect(() => {
    if (slot) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.jwt}`);
  
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
  
      console.log(value.format('YYYY-MM-DD'));
      console.log(slot);

      fetch(`https://api.ginoboxingstudio.com/api/boxing-bags?filters[time_slots]=${slot}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log('bags', result);
          setBags(result.data)
        })
        .catch(error => console.log('error', error)); 
  
      fetch(`https://api.ginoboxingstudio.com/api/reservations?filters[time_slots]=${slot}&filters[canceled][$ne]=true&populate=boxing_bag&filters[date]=${value.format('YYYY-MM-DD')}`, requestOptions)
        .then(response => response.json())
        .then(result => {
          setReservations(result);
          console.log(result);
          setBagsTaken(result.data.map((reservation) => reservation.attributes.boxing_bag.data.id));
          console.log(bagsTaken);
        })
        .catch(error => console.log('error', error));    }

  }, [slot]);

  return (
    <>
      <Helmet>
        <title> Réservation | Gino Boxing Studio </title>
      </Helmet>

      <Container maxWidth="xl" sx={{ maxWidth: 600, display: 'flex', flexDirection: 'column'}}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Réserver un cours
        </Typography>

        <Box sx={{ maxWidth: 600, display: 'flex', flexDirection: 'column'}}>
        <FormControl fullWidth>
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={'fr'}>

          <MobileDatePicker
            label="Choisir un jour"
            value={value}
            minDate={new Date()}
            onChange={(newValue) => {
              setValue(newValue);
              setSlot(null)
              setSelectedBag(0)
              setTimeSlots([])
              setBags([])
              setReservations([])
              setBagsTaken([])
          // console.log(newValue._d.getDay());
              const myHeaders = new Headers();
              myHeaders.append("Authorization", `Bearer ${user.jwt}`);

              const requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
              };

              fetch(`https://api.ginoboxingstudio.com/api/time-slots?filters[jour]=${dayComp[newValue._d.getDay()]}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                  if (!result.data) {
                    setUser(null);
                    navigate('/login', { replace: true });
                    return
                  }        
                  console.log(result);
                  setTimeSlots(result.data)

                })
                .catch(error => console.log('error', error));
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          </LocalizationProvider>
          </FormControl>
          {timeSlots.length > 0 ? (
          <FormControl fullWidth sx={{my: 2}}>
            <InputLabel id="slot-label">Choisir un créneau</InputLabel>
            <Select
              labelId="slot-label"
              id="slot"
              value={slot}
              label="Choisir un créneau"
              onChange={(event) => setSlot(event.target.value)}
            >
            {timeSlots.map((timeSlot) => (
              <MenuItem value={timeSlot.id}>{timeSlot.attributes.heure}h{timeSlot.attributes.minute === 0 ? '00' : timeSlot.attributes.minute} - {timeSlot.attributes.duration} Minutes</MenuItem>
            ))}
              {/* <MenuItem value={10}>16h-16h30</MenuItem>
              <MenuItem value={10}>16h30-17h</MenuItem>
              <MenuItem value={10}>17h-17h30</MenuItem>
              <MenuItem value={10}>17h30-18h</MenuItem> */}
            </Select>
          </FormControl>
          
          ) : (
            value !== null && <Typography>Aucun créneau disponible</Typography>
          )}
          {slot &&
          <>
          <Typography>{bags.length > 0 ? 'Choisir un sac de frappe' : 'Pas de sacs de frappe disponible'}</Typography>
          <Grid container spacing={2} columns={2}>
            <Grid item xs={1}>
              <Box
                sx={{
                  display: 'grid',
                  gap: 4,
                  gridTemplateColumns: 'repeat(2, 1fr)',
                  mt: 2
                }}
              >
                {bags.map((bag) => (
                  <Item key={bag.id} isNotAvailable={bagsTaken.includes(bag.id)} isSelected={bag.id === selectedBag} onClick={() => {
                    console.log('selected', bag.id);
                    setSelectedBag(bag.id);
                  }}>{bag.id}</Item>
                ))}
              </Box>
            </Grid>
            <Grid item xs={1} sx={{                mt: 4
}}>
              <Grid container spacing={2} sx={{height: '100%'}}   justify="flex-end"
  alignItems="center">
                <Grid item xs={6}>
                  <ItemStepper  key={"1"} isNotAvailable={bagsTaken.includes(1)} isSelected={selectedBag === 1}>{"1"}</ItemStepper>
                </Grid>
                <Grid item xs={6}>
                  <ItemStepper  key={"2"} isNotAvailable={bagsTaken.includes(2)} isSelected={selectedBag === 2} >{"2"}</ItemStepper>
                </Grid>
                <Grid item xs={12}>
                  <ItemStepper width={'50%'} key={3} isNotAvailable={bagsTaken.includes(3)} isSelected={selectedBag === 3}>{3}</ItemStepper>
                </Grid>

                <Grid item xs={6}>
                  <ItemStepper  key={4} isNotAvailable={bagsTaken.includes(4)} isSelected={selectedBag === 4}>{4}</ItemStepper>
                </Grid>
                <Grid item xs={6}>
                  <ItemStepper  key={5} isNotAvailable={bagsTaken.includes(5)} isSelected={selectedBag === 5}>{5}</ItemStepper>
                </Grid>

                <Grid item xs={12}>
                  <ItemStepper width={'50%'}  key={6} isNotAvailable={bagsTaken.includes(6)} isSelected={selectedBag === 6}>{6}</ItemStepper>
                </Grid>

                <Grid item xs={6}>
                  <ItemStepper  key={7} isNotAvailable={bagsTaken.includes(7)} isSelected={selectedBag === 7}>{7}</ItemStepper>
                </Grid>
                <Grid item xs={6}>
                  <ItemStepper  key={8} isNotAvailable={bagsTaken.includes(8)} isSelected={selectedBag === 8}>{8}</ItemStepper>
                </Grid>

              </Grid>
            </Grid>
          </Grid>

            {user.user.credits <= 0 && <Typography sx={{mt: 2}}>Vous n'avez pas assez de sessions pour réserver un sac de frappe</Typography>}
            <Button onClick={() => {
              const myHeaders = new Headers();
              myHeaders.append("Authorization", `Bearer ${user.jwt}`);
              myHeaders.append("Content-Type", "application/json");

              const requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                  "data": {
                    boxing_bag: selectedBag,
                    time_slots: slot,
                    date: value.format('YYYY-MM-DD'),
                    user: user.user.id
                  }})
              };

              fetch(`https://api.ginoboxingstudio.com/api/reservations`, requestOptions)
                .then(response => response.json())
                .then(result => {
                  if (result.data) {
                    
                  alert('Réservation effectuée avec succès');

                  setValue(null)
                  setSlot(null)
                  setSelectedBag(0)
                  setTimeSlots([])
                  setBags([])
                  setReservations([])
                  setBagsTaken([])
                  refreshUser()
                  } else {
                    alert('Une erreur est survenue');
                  }
                })
                .catch(error => console.log('error', error));
            }} sx={{my: 5}} variant="contained" disabled={user.user.credits <= 0 || selectedBag === 0}>Réserver</Button>
            </>
            }
            </Box>
      </Container>
    </>
  );
}
