import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useContext, useEffect, useState, useRef} from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// components
import { useNavigate } from 'react-router-dom';
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { useAppContext } from '../context'

// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
import USERLIST from '../_mock/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'Sac', label: 'Sac', alignRight: false },
  { id: 'Date', label: 'Date', alignRight: false },
  { id: 'Heure', label: 'Heure', alignRight: false },
  { id: 'Durée', label: 'Durée (minutes)', alignRight: false },
  { id: 'Status', label: 'Status', alignRight: false},
  { id: 'Actions', label: '', alignRight: true},
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);
  const {user, setUser} = useAppContext();
  const [reservations, setReservations] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const sessionId = useRef(null)

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [canceledClicked, setCanceledClicked] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${user.jwt}`);
  
      const requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
      };
  
      fetch(`https://api.ginoboxingstudio.com/api/reservations?filters[user]=${user.user.id}&populate=*&sort[0]=date:desc`, requestOptions)
        .then(response => response.json())
        .then(result => {
          if (result.data) {
            setReservations(result.data);
            console.log(result);
          } else {
            setUser(null);
            navigate('/login', { replace: true });
          }
        })
        .catch(error => console.log('error', error));

    }
  }, [])

  useEffect(() => {
    if (!user) {
      navigate('/login', { replace: true });
    }
  }, [user])


  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const isTimePassed = (session) => {
    const date = new Date(session.attributes.date);
    const time = session.attributes.time_slots.data?.[0]?.attributes.heure;
    const minute = session.attributes.time_slots.data?.[0]?.attributes.minute;
    const duration = session.attributes.time_slots.data?.[0]?.attributes.duration;
    const reservationDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time, minute, 0, 0);
    const now = new Date();
    const diff = reservationDate.getTime() - now.getTime();
    const diffHours = Math.ceil(diff / (1000 * 60 * 60));

    console.log(diff);
    console.log(diffHours);
    return diff <= 0
    // return date < now;
  }


  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };


  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Reservations | Gino Boxing Studio</title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Mes réservations
          </Typography>
        </Stack>
        <Dialog
          open={openDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Annuler votre session ?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Voulez-vous vraiment annuler votre session ?
              Cette action est irréversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Non</Button>
            <Button disabled={canceledClicked} onClick={() => {
                const myHeaders = new Headers();
                myHeaders.append("Authorization", `Bearer ${user.jwt}`);
                myHeaders.append("Content-Type", "application/json");
            
                setCanceledClicked(true);
                const requestOptions = {
                  method: 'PUT',
                  headers: myHeaders,
                  redirect: 'follow',
                  body: JSON.stringify({
                    "data": {
                      canceled: true
                    }})  
                };
            
                    fetch(`https://api.ginoboxingstudio.com/api/reservations/${sessionId.current}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                      setCanceledClicked(false);
                      // setReservations(result.data);
                      if (result.data) {
                        window.location.reload(false);
                      }
                      console.log(result);
                    })
                    .catch(error => {
                      setCanceledClicked(false);
                      console.log('error', error)
                    });
              // handleClose();
            }} autoFocus>
              Oui je confirme
            </Button>
          </DialogActions>
        </Dialog>

        <Card>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={USERLIST.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {reservations.map((row) => {
                    const { id } = row.attributes;
                    console.log(row);
                    // const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1}>

                        <TableCell component="th" scope="row" padding="none">
                          {row.attributes.boxing_bag.data.attributes.name}
                        </TableCell>

                        <TableCell align="left">{row.attributes.date}</TableCell>

                        <TableCell align="left">{row.attributes.time_slots.data?.[0]?.attributes.heure}h{row.attributes.time_slots.data?.[0]?.attributes.minute === 0 ? '00' : row.attributes.time_slots.data?.[0]?.attributes.minute}</TableCell>

                        <TableCell align="left">{row.attributes.time_slots.data?.[0]?.attributes.duration}</TableCell>
                        <TableCell align="left">{(isTimePassed(row) || row.attributes.time_slots.data.length === 0) ? "Passé" : (row.attributes.canceled === true ? "Annulé" : "À venir")}</TableCell>
                        <TableCell align="center">
                          {!isTimePassed(row) && row.attributes.canceled !== true && row.attributes.time_slots.data.length !== 0 &&
                          <Button onClick={() => {
                            const date = new Date(row.attributes.date);
                            const time = row.attributes.time_slots.data?.[0]?.attributes.heure;
                            const minute = row.attributes.time_slots.data?.[0]?.attributes.minute;
                            const duration = row.attributes.time_slots.data?.[0]?.attributes.duration;
                            const reservationDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), time, minute, 0, 0);
                            const now = new Date();
                            const diff = reservationDate.getTime() - now.getTime();
                            const diffHours = Math.ceil(diff / (1000 * 60 * 60));
                            if (diffHours < 24) {
                              alert('Vous ne pouvez pas annuler une réservation moins de 24h avant le début de celle-ci');
                            } else {
                              setOpenDialog(true)
                              sessionId.current = row.id;
                              console.log('tu peux annuler');
                            }
                          }}>Annuler</Button>
                        }
                        </TableCell>


                      </TableRow>
                    );
                  })}
                  
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={USERLIST.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Container>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        <MenuItem sx={{ color: 'error.main' }}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>
    </>
  );
}
