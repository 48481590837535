// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Réserver un cours',
    path: '/dashboard/app',
    icon: <SvgColor src={`/assets/icons/navbar/gant-de-boxe.png`} sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Mes réservations',
    path: '/dashboard/reservations',
    icon: <SvgColor src={`/assets/icons/navbar/reservation.png`} sx={{ width: 1, height: 1 }} />,
  },
  {
    title: 'Acheter des Sessions',
    path: '/dashboard/products',
    icon: (<SvgColor src={`/assets/icons/navbar/stopwatch.png`} sx={{ width: 1, height: 1 }} />),
  }
];

export default navConfig;
