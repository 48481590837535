import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import { useAppContext } from '../../../context'

// ----------------------------------------------------------------------

export default function ForgotForm() {
  const navigate = useNavigate();
  const {user, setUser} = useAppContext();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (user) {
      navigate('/dashboard', { replace: true });
    }
  }, [])

  const handleClick = () => {

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify({
          email,
        })
    };

    fetch(`https://api.ginoboxingstudio.com/api/auth/forgot-password`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.error) {
          alert('Email invalide')
        } else {
          alert('Un email a été envoyé à votre adresse')
        }
console.log(result);
      })
      .catch(error => alert('Email ou mot de passe incorrect')
      );

    // navigate('/dashboard', { replace: true });
  };

  return (
    <>
      <Stack spacing={3}>
        <TextField value={email} onChange={(event) => (setEmail(event.target.value))} name="email" label="Email" />
{/* 
        <TextField
          name="password"
          label="Mot de passe"
          value={password} onChange={(event) => (setPassword(event.target.value))} 
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        /> */}
      </Stack>

      {/* <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        <Link variant="subtitle2" underline="hover">
          Mot de passe oublié?
        </Link>
      </Stack> */}

      <LoadingButton sx={{ my: 2 }} fullWidth size="large" type="submit" variant="contained" onClick={handleClick}>
        Réinitialiser
      </LoadingButton>
    </>
  );
}
